import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Card, Button, Form, Tabs, Tab , OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faFileDownload,faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toggleMenuDisplay, setFilters, setClients, setUserPreferedLanguage, setLanguageTranslations, setDemoClient } from "./actions/AppActions";
import logo from "./logo.svg";
import "./B9App.css";
import "./App.css";
import "./DDC5App.css";
import "./css/styles.css";
import "./css/bootstrap.min.css";
import "./css/fonts/fonts.css";
import B9MenuComponent from "./components/B9Components/B9MenuComponent";
import B9DataSection from "./components/B9Components/B9DataSection";
import MapB9 from "./components/B9Components/MapB9";
import ClientFilter from './components/Header/ClientFilter'
import feedbackSaved from '../src/Images/check-circle-regular.svg';

import { BASE_URL, MAC_ID, ADMIN, ENVIRONMENT, ENTERPRISE_ID } from "./StringConstants";
// import * as api from "./services/API";
import * as api from '../src/services/apiAction'

import AuthContext from "./services/Auth";
import { Modal } from "react-bootstrap";
import sisenseSettings from "./config/Sisense";
import ReactTooltip from "react-tooltip";
import IdleTimer from 'react-idle-timer'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { InteractionRequiredAuthError } from '@azure/msal-browser'


import { loginRequest, loginRequestPowerBi } from '../src/services/authProvider'
import { withMsal } from "@azure/msal-react";
import Connecting from "./Connecting";


const guid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

const sisenseIsLoaded = () => {
  return ((typeof window.Sisense !== "undefined") && (typeof window.prism !== "undefined"));
}

class B9App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tab1Config: [],
      tab2Config: [],
      tab3Config: [],
      tab4Config: [],
      tab5Config: [],
      showMenu: true,
      isValidLicense: false,
      userDetails: {},
      defaultRoute: "",
      invalidUser: false,
      licenseChecked: false,
      showHumanAndMachine: false,
      showIntOpsJourney: false,
      showOperations: false,
      showBusinessOutcome: false,
      showAppStore: false,
      showModal: false,
      currentApp: null,
      isConnected: ENVIRONMENT === 'local', //Change this to false before building - LOCAL
      count: 0,
      showAppStoreHome: true,
      selectClientHolderDiv: true,
      hideProfileHolderDiv: true,
      selectedClient: {},
      isLoggingEnabled: true,
      activeSubTabId: 0,
      activeSectionView: '',
      activeAppId: 0,
      lastUserEngagementRecord: {},
      tabSelected: '',
      isCheckedTheme: true,
      languageList: {},
      profileName: "",
      showHMtab: true,
      announcements: [],
      LoggedInUser: {},
      showConfPopup: true,
      showProfile: false,
      isSisenseConnectCalled: false,
      showConfidentialModal:true,
      selectedTab:"Alert",
      feedbackBody2:"",
      totalfileCountError2: false,
      showRequestAccessModal:false,
      requestAccessCommentText:"Please provide the User E-mail, Client name & Purpose of access.",
      selectedFile2: [],
      fileSizeError2: false,
      fileExtensionError2: false, 
      duplicateFileErrorMsg2: false,
      showSuccessPopup:false,
      isChatbotEnabled:false
    };
    this.idleTimer = null

  }
  //newchange
  parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };
  delay = () => {
    // `delay` returns a promise
    return new Promise(function (resolve, reject) {
      // Only `delay` is able to resolve or reject the promise
      setTimeout(function () {
        resolve(42); // After 3 seconds, resolve the promise with value 42
      }, 2000);
    });
  }
  updatePBToken = async () => {
    await this.delay();
    // Set the new access token
    if (window.report) {
      await window.report.setAccessToken(localStorage.getItem("access_token_powerbi"));
      await window.report.reload();
      let reportList = document.getElementsByClassName("power-bi-frame");
      if (reportList.length > 0) {
        for (let p = 0; p < reportList.length; p++) {
          reportList[p].querySelector("iframe").src = reportList[p].querySelector("iframe").src;
        }
      }
    }
  }

  refreshPowerBi = () => {

    const msalInstance = this.props.msalContext.instance;

    //powerbi access token
    if (this.props.msalContext.accounts.length > 0) {
      const account = this.props.msalContext.accounts[0];
      msalInstance.acquireTokenSilent({
        ...loginRequestPowerBi,
        account: account
      }).then((response) => {
        // console.log("PowerBi token renewed with popup:" + JSON.stringify(response));
        localStorage.removeItem("access_token_powerbi");
        localStorage.setItem("access_token_powerbi", response.accessToken);
        this.updatePBToken();
      }).catch(err => {
        //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
        //always check if the error is something that requires auth login interaction 
        if (err instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenPopup({
            ...loginRequestPowerBi,
            account: account
          }).then((response) => {
            // console.log("PowerBi token renewed with popup:" + JSON.stringify(response));
            localStorage.removeItem("access_token_powerbi");
            localStorage.setItem("access_token_powerbi", response.accessToken);
            this.updatePBToken();
          }).catch(err => {
            console.log('Error getting popup token: ' + JSON.stringify(err))
          });
        }
      });
    }
  }

  //newchangeend

  getAnnouncements = () => {
    api.get("announcements/1").then((resp) => {
      if (resp.length > 0) {
        this.setState({ announcements: resp });
      } else {
        this.setState({ showHMtab: false, announcements: [] });
      }
    });
  }

  showAnnouncement = (id, checked) => {
    api.get(`announcements/allowpopup/${id}/CommandCenter/${checked}`).then((resp) => {
      if (resp.length > 0) {
        this.setState({ popupAnnouncement: !checked })
      }
    });
  }

  confidentialPopUp = (flag, id) => {
    api.get(`setb9agreement/${id}/CommandCenter`).then((resp) => {
      this.setState({ showConfPopup: flag, showConfidentialModal: false });
    })
  }

  login = () => {
    const accounts = this.props.msalContext.accounts;
    const msalInstance = this.props.msalContext.instance;

    msalInstance.handleRedirectPromise().then(tokenResponse => {
      if (!tokenResponse) {

        if (accounts.length === 0) {
          msalInstance.loginRedirect(loginRequest);
        } else if (accounts.length === 1) {
          this.getLanguageList();
          this.getAnnouncements();
          this.handleClickOnScreen()
          this.setupBeforeUnloadListener()
          this.checkAnalyticBotEnablement();
        }
      } else {
        this.getLanguageList();
        this.getAnnouncements();
        this.handleClickOnScreen()
        this.setupBeforeUnloadListener()
        this.checkAnalyticBotEnablement();
      }
    }).catch(err => {
      // Handle error
      console.error(err);
    });

  }

  componentDidMount() {
    if (ENVIRONMENT !== "local") {
      this.login();
    } else {
      this.getLanguageList();
      this.getAnnouncements();
      this.handleClickOnScreen()
      this.setupBeforeUnloadListener()
      this.checkAnalyticBotEnablement();
    }

    setInterval(() => {
      //IF TOKEN IS IN 5 MIN BEFORE EXPIRATION THEN TRIGGER REFRESH
      //For testing purposes, 3mins token refresh is set
      try {
        // console.log((this.parseJwt(localStorage.getItem('access_token_powerbi')).exp - (Date.now() / 1000)))
        if (ENVIRONMENT !== 'local') {
          if ((this.parseJwt(localStorage.getItem('access_token_powerbi')).exp - (Date.now() / 1000)) < window.msalConfig.exptimeCompare) {
            if (this.state.userDetails.isPowerBIEnabled) {
              this.refreshPowerBi()
            }
          }
        }
      } catch (e) { console.log(e) }

    }, window.msalConfig.tokenRefreshTime);
  }
  getLanguageList = () => {
    api
      .get(`languages`)
      .then((res) => {
        const langList = res;
        this.setState({
          languageList: langList
        }, () => this.getUserDetails())
      })
      .catch((error) => {
        //Check if it is a duplicate entry

      });
  }
  handleLanguageChange = (event) => {
    this.props.setUserPreferedLanguage(event.target.value, event.target.id);
    let selectedLanguage = event.target.value
    let body = {
      Id: this.state.userDetails.id,
      Language: event.target.value,
      ShowGlobe: true,
      Theme: "blue",
      Application: "CommandCenter"
    }
    api.post(`userpreference`, body)
      .then((res) => {
        if (res === 'Success') {
          this.getTranslations(selectedLanguage)
        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry


      });

  }
  handleClickOnScreen = () => {
    window.addEventListener('click', (e) => {
      let selectClientHolderDivArr = document.getElementsByClassName('selectClientHolderDivB9')
      let hmccColArr = document.getElementsByClassName('clientDropDown')
      if (selectClientHolderDivArr.length > 0 && selectClientHolderDivArr[0].contains(e.target)) {
      }
      else if (hmccColArr.length > 0 && hmccColArr[0].contains(e.target)) {
        this.handleSelectClientToggle()
      }
      else {
        if (!this.state.selectClientHolderDiv) {
          this.setState({
            selectClientHolderDiv: true
          })
        }
      }
    });
  }
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      //ev.preventDefault();
      ev.returnValue = "";
      return this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, false)
    });
  };

  announcementPopUp = (flag) => {
    this.setState({ showHMtab: flag, showProfile: true });
  }

  handleSelectClientToggle = (e) => {
    this.setState({
      selectClientHolderDiv: !this.state.selectClientHolderDiv,
    })
  }
  handleProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: false
    })

  }
  handleCloseProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: true
    })
  }
  _handleChangeTheme = () => {
    this.setState({ isCheckedTheme: !this.state.isCheckedTheme });
  }
  loadSisensejs = () => {
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      let count = this.state.count + 1;
      this.setState({ count })
      if (count < 3) {
        setTimeout(this.ConnectJS(), 5000);
      }
      else {
        console.log("Error- while connecting to sisenseJS server");
      }
    }
    else {
      const Sisense = window.Sisense;
      Sisense.connect(sisenseSettings.server).then(app => {
        this.setState({
          currentApp: app,
          isConnected: true
        });
      });
    }
  }
  ConnectJS = () => {
    this.setState({
      isSisenseConnectCalled: true
    })
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = sisenseSettings.server + '/js/sisense.js?v=' + guid();
      script.async = true;
      script.onload = this.loadSisensejs;
      document.body.appendChild(script);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant
    ) {
      this.getConfigData(nextProps.app.filterObject.tenant, nextProps.app.filterObject.tenantId);
    }
  }
  hideModal = () => {
    this.setState({ showModal: false });
  }



  getTranslations = (languageCode) => {
    api
      .get(`translatedtext/${languageCode}`)
      .then((res) => {
        let literals = {};
        literals[languageCode] = { translation: res };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: languageCode,
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
        this.props.setLanguageTranslations(res);
      })
      .catch((err) => {
        let literals = {};
        literals["en"] = { translation: {} };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: "en",
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
      })
  }
  getTranslatedText = (key) => {
    let value = "";
    value = this.props.app.languageTranslationArray[key] ? this.props.app.languageTranslationArray[key] : key;
    return value;
  }
  getUserDetails = () => {
    //local
    let enterpriseId = '';
    if (ENVIRONMENT === 'local') {
      enterpriseId = ENTERPRISE_ID;
      this.setState({ profileName: enterpriseId });
    }
    else {
      let userName = this.props.msalContext.accounts.length > 0 && this.props.msalContext.accounts[0].username;
      enterpriseId = userName.split("@")[0];
      this.setState({ profileName: enterpriseId });

    }
    api
      .get(`useriddetails`)
      .then((res) => {
        //Check if it is a duplicate entry
        if (res.status == 400) {
          this.setState({ invalidUser: true });
          this.props.setUserPreferedLanguage('en', 'English');
        }
        else {
          const userDetails = res;
          if (userDetails.userPreference.allowAnnouncementAutoPopup == false) {
            this.setState({ showAnnouncements: false });
          }

           else{
             this.setState({showAnnouncements: true, selectedTab:"Announcement"});
          }
          if (userDetails.userPreference.b9Agreement == true) {
            this.setState({ showConfPopup: false, showConfidentialModal: false });
          }
          let langText = (this.state.languageList.find(obj => obj.languageCode == userDetails.userPreference.language)).languageDescription;
          this.props.setUserPreferedLanguage(userDetails.userPreference.language, langText);
          this.getTranslations(userDetails.userPreference.language);
          let route = "";
          //default route
          if (
            (userDetails.featureIds && userDetails.featureIds.some((val) => val == 28)) ||
            userDetails.dashboardRole == ADMIN
          ) {
            //this.checkIfTrackingEnabled('Human and Machine', 0, 0, userDetails)
            route = "humanAndMachine";
            this.setState({ showHumanAndMachine: true, tabSelected: 'T2' });
          } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 26)) {
            //this.checkIfTrackingEnabled('IOJ', this.state.activeSubTabId, 0, userDetails)

            route = "intOps";
            this.setState({ showIntOpsJourney: true, tabSelected: 'T1' });
          } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 30)) {
            //this.checkIfTrackingEnabled('Operations', 0, 0, userDetails)

            route = "operations";
            this.setState({ showOperations: true, tabSelected: 'T3' });
          } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 32)) {
            //this.checkIfTrackingEnabled('Business Outcome', 0, 0, userDetails)

            route = "businessOutcome";
            this.setState({ showBusinessOutcome: true, tabSelected: 'T4' });
          } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 59)) { //Dummy id
            //this.checkIfTrackingEnabled('SynOps Apps', 0, 0, userDetails)

            route = "appStore";
            this.setState({ showAppStore: true, tabSelected: 'T5' });
          }
          this.setState({ userDetails: userDetails, defaultRoute: route, isConnected: !userDetails.isSisenseEnabled });  //Change to isConnected: 'true' while running in Local
            if (ENVIRONMENT !== 'local' && !this.state.isSisenseConnectCalled && userDetails.isSisenseEnabled) {
              this.ConnectJS();
            }
          //newchange
          if (ENVIRONMENT !== 'local' && userDetails.isPowerBIEnabled) {
            this.refreshPowerBi();
          }
          //Validate license
          let macid = MAC_ID;
          api
            .get(`validatelicensekeys/${macid}`)
            .then((res) => {
              //Check if it is a duplicate entry
              if (res.status == 400) {
                this.setState({ isValidLicense: false, licenseChecked: true });
              }
              else {
                this.setState({ isValidLicense: true, licenseChecked: true });
                this.getConfigData(this.props.app.filterObject.tenant, this.props.app.filterObject.tenantId);
                if (userDetails.tenant.length == 1) {
                  this.onSelectClient(userDetails.tenant[0]);
                  this.setState({ selectedClient: userDetails.tenant[0] });
                }
              }
            })
            .catch((error) => {
              //Check if it is a duplicate entry


            });
        }
      })
      .catch((error) => {
        this.props.setUserPreferedLanguage('en', "English");
      });
  };
  checkIfTrackingEnabled = (sectionView, subTabId, appId, prevSectionView, userDetails, tenantId) => {
    //COMMENT FOR PROD
    api
      .get(`userengagement/trackingconfigs/` + tenantId)
      .then((res) => {
        if (res) {
          let prevLoggingEnabled = this.state.isLoggingEnabled
          this.setState({
            isLoggingEnabled: res.isLoggingEnabledCC,
            activeSectionView: sectionView,
            prevLoggedInEnabled: prevLoggingEnabled
          }, () => {
            if (res.isLoggingEnabledCC) {
              this.callUserEnagementSessionAPI(sectionView, subTabId, appId, true, prevSectionView, userDetails)

            }
            else if (prevLoggingEnabled) {
              this.callUserEnagementSessionAPI(sectionView, subTabId, appId, false)
            }
          })

        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry


      });
  }
  getConfigData = (tenant, tenantId) => {
    //local
    let enterpriseId = '';
    if (ENVIRONMENT === 'local') {
      enterpriseId = ENTERPRISE_ID;
    }
    else {
      let userName = this.props.msalContext.accounts.length > 0 && this.props.msalContext.accounts[0].username;
      enterpriseId = userName.split("@")[0];
    }
    //Get all config data
    api.post(`hmccconfigurations`, [...tenant]).then((res) => {
      const result = res;
      let tab1Config = [];
      let tab2Config = [];
      let tab3Config = [];
      let tab4Config = [];
      let tab5Config = [];
      result.map((item) => {
        switch (item.screenSection) {
          case "T1":
            {
              tab1Config.push(item);
            }
            break;
          case "T2":
            {
              tab2Config.push(item);
            }
            break;
          case "T3":
            {
              tab3Config.push(item);
            }
            break;
          case "T4":
            {
              tab4Config.push(item);
            }
            break;
          case "T5":
            {
              tab5Config.push(item);
            }
            break;
          default:
            break;
        }
      });
      this.setState({ tab1Config, tab2Config, tab3Config, tab4Config, tab5Config });
      //Check if user has apps in app store(Configured by admin)
      let client = tenant.length == 1 ? tenant[0] : null;
      let clientId = tenantId.length == 1 ? tenantId[0] : 0;
      api.get(`appstoreconfigs/` + client + '/' + clientId + '/' + this.state.userDetails.id).then((resp) => {
        if (resp.length > 0) {
          this.setState({ showAppStoreHome: true });
        }
      });
    });
  }
  callUserEnagementSessionAPI = (sectionView, subTabId, appId, insertRecord, previousSectionView, userDetails) => {
  }

  setActiveScreen = (value) => {
    if (this.state.tabSelected != value) {
      let preSectionView = this.state.activeSectionView
      switch (value) {
        case "T1":
          {
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: true,
              showOperations: false,
              showBusinessOutcome: false,
              showAppStore: false,
              tabSelected: value,
              activeSectionView: 'IOJ'
            }, () => {
              this.callUserEnagementSessionAPI('IOJ', this.state.activeSubTabId, 0, true, preSectionView)

            })
          }
          break;
        case "T2":
          {
            this.setState({
              showHumanAndMachine: true,
              showIntOpsJourney: false,
              showOperations: false,
              showBusinessOutcome: false,
              showAppStore: false,
              tabSelected: value,
              activeSectionView: 'Human and Machine'
            }, () => {
              this.callUserEnagementSessionAPI('Human and Machine', 0, 0, true, preSectionView)
            })
          }
          break;
        case "T3":
          {
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: false,
              showOperations: true,
              showBusinessOutcome: false,
              showAppStore: false,
              tabSelected: value,
              activeSectionView: 'Operations'
            }, () => {
              this.callUserEnagementSessionAPI('Operations', 0, 0, true, preSectionView)
            })
          }
          break;
        case "T4":
          {
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: false,
              showOperations: false,
              showBusinessOutcome: true,
              showAppStore: false,
              tabSelected: value,
              activeSectionView: 'Business Outcome'
            }, () => {
              this.callUserEnagementSessionAPI('Business Outcome', 0, 0, true, preSectionView)
            })
          }
          break;
        case "T5":
          {
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: false,
              showOperations: false,
              showBusinessOutcome: false,
              showAppStore: true,
              tabSelected: value,
              activeSectionView: 'SynOps Apps'
            }, () => {
              if (preSectionView == 'Human and Machine' && this.props.app.filterObject.deliveryLocation.length > 0) {
                this.callUserEnagementLocationAPI(this.props.app.filterObject.deliveryLocation, this.state.lastUserEngagementRecord)
              }
            })
          }
          break;
        default:
          break;
      }
    }
  }

  toggleTopMenu = () => {
    this.props.toggleMenuDisplay(!this.state.showMenu);
    this.setState({ showMenu: !this.state.showMenu });
  };
  showImage = (imageDetails) => {
    return imageDetails.iconName != null && imageDetails.iconName != '' && imageDetails.appIcon != null && imageDetails.appIcon != ''
  }
  getImageSrc = (client) => {
    const appIcon = client.appIcon
    const iconName = client.iconName

    if (iconName != null) {
      var n = iconName.lastIndexOf('.');
      var extn = iconName.substring(n + 1);
      var imageSrc = extn == 'svg' ? "data:image/svg+xml;base64, " + appIcon : "data:image/png;base64, " + appIcon
      return imageSrc
    }
    else {
      return 'data:image/jpeg;base64, ' + appIcon
    }
  }
  onSelectClient = (client) => {

    let filterObject = { ... this.props.app.filterObject }
    let selectedClients = []
    if (client && client.tenantName) {
      filterObject.tenant = [client.pseudoName]
      selectedClients = [client.tenantName]
      filterObject.tenantId = [client.tenantID]
    }
    else {
      filterObject.tenant = []
      filterObject.tenantId = []
    }
    this.setState({
      selectedClient: client,
      selectClientHolderDiv: true,
      searchedClientText: ''
    }, () => {
      if (client && client.tenantName) {
        this.checkIfTrackingEnabled(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, true, this.state.userDetails, client.tenantID)
      }
      else { //means user selected all clients
        this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, true, this.state.activeSectionView)
      }
    })
    this.props.setFilters(filterObject);
    this.props.setClients(selectedClients);

    if (client.isDemo) {
      this.props.setDemoClient(true)
    } else {
      this.props.setDemoClient(false)
    }

  }
  handleOnIdle = (event) => {
    this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, false, this.state.activeSectionView);
  }

  callUserEnagementLocationAPI = (selectedLocations, lastUserEngagementRecord) => {
    let arrLocations = selectedLocations.map(element => {
      return (
        {
          UserId: this.state.userDetails.id,
          Location: element,
          SessionId: localStorage.getItem('adal.session.state') ? localStorage.getItem('adal.session.state') : '0',
          UserEngagementRecordId: lastUserEngagementRecord.recordId

        })
    });


    let tenantID = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0

    api.post(`userlocations/` + tenantID, [...arrLocations]).then((res) => {

    });
  }

  onChangeDeliveryLocations = (selectedLocations) => {
    this.callUserEnagementLocationAPI(selectedLocations, this.state.lastUserEngagementRecord)

  }
  switchHumanAndMachineTab = (flag) => {
    this.setState({ showHMtab: flag });
  }

  sendFeedback2 = (desc, messageType, file) => {
    let formData = new FormData();
    if (file.length > 0) {
    
       this.state.selectedFile2.map((item, i) => {
         formData.append("file" + i, item,item.name);
       })
    }
    formData.append("messageType",messageType);
    formData.append("description",desc);
    api.post(`emailmessages`,formData,true).then((resp) => {
      if (resp > 0 || resp.length > 0) {
        this.setState({ showSuccessPopup:true});
      }
    });
  }
  handleRequestFile = (e) => {
    e.preventDefault();
    let images = e.target.files;
    var i;
    this.setState({
      fileSizeError2: false,
      fileExtensionError2: false,
      totalfileCountError2: false,
      duplicateFileErrorMsg2: false
    });
    if (images) {
      if(this.state.selectedFile2.length + e.target.files.length > 3){
        this.setState({totalfileCountError2: true});
        return;
      }
      var allFileSizes = 0;
     
      for (i = 0; i < e.target.files.length; i++) {
        var repeat = this.state.selectedFile2.filter(a => {
          if (a.name === images[i].name) {
            return a;
          }
        })
        this.state.selectedFile2.map(a => {
          let size = (a.size / 1048576);
          allFileSizes = allFileSizes + size;
  
        });
        var fileSize = (images[i].size / 1048576);
        if (!images[i].name.match(/.(jpg|png|pdf|docx)$/i)) {
          this.setState({ fileExtensionError2: true });
        }
        else if (fileSize + allFileSizes > 15) {
          this.setState({ fileSizeError2: true });
        }
        else if(repeat.length > 0){
          this.setState({
            duplicateFileErrorMsg2: true
          })
        }
        else {
          this.state.selectedFile2.push(images[i]);

          //  this.state.fileContent.push(images[i]);
          
        }

      }
    }
    if (!this.state.fileExtensionError2 && !this.state.fileSizeError2 && !this.state.totalfileCountError2 && !this.state.duplicateFileErrorMsg2) {
      this.setState({
        selectedFile2: this.state.selectedFile2,

      })
    }
    e.target.value = '';
  };

  deleteRequestIcon = (e) => {
    var index = e;
    if (index !== -1) {
      this.state.selectedFile2.splice(index, 1);
      this.setState({ selectedFile2: this.state.selectedFile2 });
    }
    if(this.state.selectedFile2.length <= 3){
      this.setState({
        totalfileCountError2: false
      })
    }
    if(this.state.selectedFile2.length == 0){
      this.setState({
        fileSizeError2: false,
        fileExtensionError2: false
      })
    }
  };
  handleRequestAccessChange = ( value) => {
    this.setState({
      feedbackBody2: value,
    });
};
showReqAccessModal = () => {
  this.setState({
    showRequestAccessModal: true,
    feedbackBody2: '',
    feedbackSelected: null,
    totalfileCountError2: false,
    duplicateFileErrorMsg2: false,
    selectedFile2 : [],
    fileExtensionError2: false,
    fileSizeError2: false,
    messageType: "reportanissue"
  })
}
hideRequestAccessModal = () => {
  this.setState({
    showRequestAccessModal: false
  })
}
hideSuccessModal = ()=>{
  this.setState({
    showSuccessPopup: false
  })
}
submitReqAccess = () => {
  this.sendFeedback2(this.state.feedbackBody2, "access", this.state.selectedFile2);
  this.setState({
    showRequestAccessModal: false,
    feedbackBody2: '',
  })
}
//Check if Analytic bot is enabled
checkAnalyticBotEnablement = ()=>{
  try {
    api.get("Get-AnalyticBotFlag")
      .then((response) => {
        this.setState({isChatbotEnabled: response==1?true:false});
      })
      .catch((error) => {});
  } catch (exception) {}
}
  render() {

    return (
      <div className='container-B9' data-test='app-component'>
        <BrowserRouter>
        {this.state.invalidUser && (
          <div>
            <Card style={{border: "none"}}>
              <Card.Body className="access-denied" style={{height:"100vh", width:"99vw"}}>
                <img style={{marginTop: "16rem"}} src={require("./images/Access_denied.svg")}/>
                <div style={{fontFamily: "GraphicMedium !important",fontSize: "1.2rem", color: "#9400D3",textShadow:"0px 0px, 0px 0px, 0px 0px"}}>Access Denied</div>
                <div style={{marginTop: "1rem", marginLeft: "1rem"}}>
                  You do not have access to the Synops Hub application.
                </div>
                <div>
                  If you need access to Synops Hub, please{" "}
                  <span className="getAccess" onClick={() => this.showReqAccessModal()}>
                    click here
                  </span>.
                </div>
                {/* <div  onClick={this.sendEmail} style={{marginRight:"12rem"}}>  */}
                <div style={{marginRight: "-4rem"}}>
                The support team would reach out to you with the next steps.{" "}
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
          {/* {this.state.invalidUser && (
            <div>
              <Card>
                <Card.Body style={{ textAlign: "center", background: "#F0B8B8" }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                  <span style={{ fontWeight: "bold" }}>User does not have access.</span>
                </Card.Body>
              </Card>
            </div>
          )} */}
          {!this.state.isValidLicense && !this.state.invalidUser && this.state.licenseChecked && (

            <div>
              <Card>
                <Card.Body style={{ textAlign: "center", background: "#F0B8B8" }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                  <span style={{ fontWeight: "bold" }}> Licence has expired. Please contact Administrator.</span>
                </Card.Body>
              </Card>
            </div>
          )}
          <div id="sisenseApp">
            {this.state.isValidLicense &&
              this.state.isConnected &&
              (this.state.tab2Config.length > 0) &&
              !this.state.invalidUser &&
              this.state.defaultRoute ? (
                <div >

                  <div className="row">
                    <div className='col-left p-0' style={{ background: "#111827" }}>
                      <B9MenuComponent userDetails={this.state.userDetails}
                        profileName={this.state.profileName}
                        config={this.state.tab1Config}
                        appStoreConfig={this.state.tab5Config}
                        isConnected={this.state.isConnected}
                        currentApp={this.state.currentApp}
                        callUserEnagementSessionAPI={this.callUserEnagementSessionAPI}
                        getTranslations={this.getTranslations}
                        getTranslatedText={this.getTranslatedText}
                        showHMtab={this.state.showHMtab}
                        selectedClient={this.state.selectedClient}
                        switchHumanAndMachineTab={this.switchHumanAndMachineTab}
                        announcementPopUp={this.announcementPopUp}
                        announcements={this.state.announcements}

                        showPopUp = {this.showAnnouncement}
                        showProfile = {this.state.showProfile}
                      //  showAnnouncements = {this.state.showAnnouncements}
                        getAnnouncements = {this.getAnnouncements}
                        popupAnnouncement= {this.state.popupAnnouncement} 
                        selectedTab= {this.state.selectedTab}
                        onSelectClient={this.onSelectClient}
                        updatePBToken={this.updatePBToken}
                        isChatbotEnabled={this.state.isChatbotEnabled}/>
                    </div>

                    <div className='col-middle p-0' style={{ background: "#111827", height: "100vh" }}>
                      {((this.state.userDetails.featureIds &&
                        this.state.userDetails.featureIds.some(
                          (val) => val == 28
                        )) || this.state.userDetails.dashboardRole == ADMIN) &&
                        (<MapB9
                          userDetails={this.state.userDetails}
                          config={this.state.tab2Config}
                          isLoggingEnabled={this.state.isLoggingEnabled}
                          onChangeDeliveryLocations={this.onChangeDeliveryLocations}
                          getTranslations={this.getTranslations}
                          getTranslatedText={this.getTranslatedText}
                          onSelectClient={this.onSelectClient}
                          selectClientHolderDiv={this.state.selectClientHolderDiv}
                          showImage={this.showImage}
                          getImageSrc={this.getImageSrc}
                          selectedClient={this.state.selectedClient}
                          showConfidentialModal={this.state.showConfidentialModal}
                          showConfPopup={this.state.showConfPopup}
                          confidentialPopUp={this.confidentialPopUp}
                        />
                        )}
                    </div>

                    <div className='col-right p-0 b9-3rd-section'>
                      <B9DataSection
                        userDetails={this.state.userDetails}
                        config={this.state.tab3Config.concat(this.state.tab4Config)}
                        currentApp={this.state.currentApp}
                        isConnected={this.state.isConnected}
                        getTranslations={this.getTranslations}
                        getTranslatedText={this.getTranslatedText}
                      />
                    </div>
                  </div>

                  <Modal
                    show={this.state.showModal}
                    onHide={() => this.hideModal()}
                    backdrop="static"
                    dialogClassName="modal-90w popup-width"
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header
                      closeButton={true}
                      className="dashboard-modal-header"
                    ></Modal.Header>
                    <Modal.Body>
                      <div style={{ color: 'white', textAlign: 'center' }}><h6>Pop-up Blocker is enabled! Please add synopshub.accenture.com to your exception list for a seemless experience.</h6></div>
                    </Modal.Body>
                  </Modal>


                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 55}
                    onIdle={this.handleOnIdle}
                    debounce={250}
                  />
                </div>
              ) : (!this.state.invalidUser && (<Connecting />))}
              </div>
              <Modal
                show={this.state.showRequestAccessModal}
                onHide={() => this.hideRequestAccessModal()}
                // dialogClassName="feedback-modal"
                size="lg"
              >
                 <Modal.Header closeButton style={{borderBottom: "none"}}>
                 <div>
                  <span style={{marginLeft: "0.8rem", fontSize: "1.1rem"}}>
                    Request Access
                  </span>
                </div>
                </Modal.Header>
                <Modal.Body
                  // className="feedback-modal"	
                  style={{ minHeight: "45vh" }}
                >
                  <Form>
                    <div>
                      <Form.Group>
                      <Form.Control
                            className='feedback-select'
                            placeholder='Command Center'
                            readOnly
                          />
                          
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1" style={{ marginLeft: '16px' }}>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            //  maxLength={500}
                            placeholder={this.state.requestAccessCommentText}
                            onChange={(e) =>
                              this.handleRequestAccessChange(e.target.value)
                            }
                            value={this.state.feedbackBody2}
                            style={{ resize: "vertical", minHeight: "100px",width:"98%" }}
                          >
                          </Form.Control>
                        </Form.Group>
                        <div class="row">
                          <div class="form-group col-md-12">
    
                          <label for="filePicker" className="attachmentPosition" onChange={this.handleRequestFile} >
                              <FontAwesomeIcon className="plusButton" icon={faPlusCircle} />
                              Add Attachment
                              <input id="filePicker" style={{ visibility: "hidden",marginLeft:"-25rem"}} type="file" multiple />
                              </label>
                              <div className="fileColor filetypeFont">(.jpg, .png, .pdf, .docx)</div>
                              <div className="fileColor attachmentFont">**(3 attachments upto 15 MB are permitted)</div>
    
                            {(this.state.selectedFile2.length > 0) &&
                              // this.state.selectedFile.map(files => <p style={{float:"right"}}>{files[2].name}</p>)
                              <p className="uploaded_file">
    
                                {this.state.selectedFile2.map((name, index) => <p style={{fontWeight:"bold",marginBottom:"0"}}>{name.name}
                                  <span key={index} onClick={() => this.deleteRequestIcon(index)} className="ml-2 crossIcon" style={{cursor: "pointer" }}>x</span>
                                </p>)}
    
                              </p>
                            }
    
                          </div>
                          {this.state.fileExtensionError2 && (
                            <p className="fileUploadMessage">
                              Please upload valid file.
                            </p>
                          )}
                          {this.state.fileSizeError2 && (
                            <p className="fileSizeMessage">
                              The file you are attaching exceeds the specified limit.
                            </p>
                          )}
                          {this.state.totalfileCountError2 && (
                              <p className="morefilesErrorMessage">
                              Maximum 3 files can be uploaded.
                            </p>
                          )}
                          {this.state.duplicateFileErrorMsg2 && (
                            <p className="fileErrorMessage">
                            The file has already been uploaded.
                          </p>
                          )}
                        </div>
                      </div>
                      </Form>
                        </Modal.Body><Modal.Footer className="feedback-footer">
                          <Button className='footer-btn submitFeedbackFocus' disabled={(this.state.feedbackBody2.trim().length == 0)} onClick={() => this.submitReqAccess()}>Submit</Button>
                        </Modal.Footer>
                    </Modal>
                    
                    <Modal
                       show={this.state.showSuccessPopup}
                       onHide={() => this.hideSuccessModal()}
                      dialogClassName=""
                      className="feedbackSubmitted-modal requestSuccess"
                      backdrop="static"
                    >
                      <Modal.Header closeButton style={{borderBottom: "none"}}>
                        <Modal.Title style={{color: "none"}}>
                          <span style={{color: "black"}}>Request Access</span>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        // className="feedback-modal"
                        style={{height: "22vh"}}
                      >
                        <div>
                          <div style={{padding: "25px"}}>
                            <img
                              src={feedbackSaved}
                              height="24px"
                              width="24px"
                              className="feedback-submit"
                              style={{marginTop:'0'}}
                            ></img>
                            <span className="feedbackSubmittext">
                              Access Request Submitted Successfully
                            </span>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
        </BrowserRouter>
      </div>)
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
    setClients: (arr) => {
      dispatch(setClients(arr));
    },
    setUserPreferedLanguage: (code, name) => {
      dispatch(setUserPreferedLanguage(code, name));
    },
    setLanguageTranslations: (arr) => {
      dispatch(setLanguageTranslations(arr));
    },
    setDemoClient: (bool) => {
      dispatch(setDemoClient(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMsal(B9App));
